import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { Send } from "react-bootstrap-icons";
import Spinner from "react-bootstrap/Spinner";

const { REACT_APP_BASE_URL } = process.env;

const Home = () => {
  const [savedInputQuestion, setSavedInputQuestion] = useState("");
  const [inputQuestion, setInputQuestion] = useState("");
  const [result, setResult] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const onButtonSubmit = async (event) => {
    event.preventDefault();
    setResult();
    setIsLoading(true);

    const response = await fetch(REACT_APP_BASE_URL + "/getAnswer", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ question: inputQuestion }),
    });

    const data = await response.json();
    setResult(data.result);
    setSavedInputQuestion(inputQuestion);
    setInputQuestion("");
    setIsLoading(false);
  };

  const enterQuestion = (e) => {
    e.preventDefault();

    setInputQuestion(e.target.value);
  };

  function replaceWithBr(answer) {
    return answer.replace(/\n/g, "<br />");
  }

  return (
    <>
      {isLoading ? (
        <Spinner
          animation="border"
          role="status"
          style={{ position: "absolute", top: "50%", left: "50%" }}
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : null}
      <div
        className="m-2  text-center d-flex flex-column justify-content-center"
        style={{
          height: "90vh",
        }}
      >
        {result ? (
          <>
            <div
              style={{
                margin: "0 15vw",
                textAlign: "left",
                overflowY: "auto",
              }}
              dangerouslySetInnerHTML={{
                __html:
                  `<strong>Q. ${savedInputQuestion} </strong> <br></br>` +
                  replaceWithBr(result),
              }}
            ></div>
          </>
        ) : (
          ""
        )}
      </div>
      <div className="text-center fixed-bottom col-sm-12">
        <Form className="row" onSubmit={onButtonSubmit}>
          <Form.Group
            className="mb-3 mt-3"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Form.Control
              className="col-8"
              placeholder="Enter your Question here"
              onChange={enterQuestion}
              style={{ width: "40%", position: "relative" }}
              value={inputQuestion}
              required
            />
            <span>
              <Button
                type="submit"
                className="btn-default col-1 w-100"
                disabled={isLoading}
              >
                <Send />
              </Button>
            </span>
          </Form.Group>
        </Form>
        <a href="https://www.turko.ca">Turko</a>
      </div>
    </>
  );
};

export default Home;
